import React from 'react';
import GalleryContent from "./GalleryContent";

function Gallery() {
    return (
        <div className="home">
            <GalleryContent />
        </div>
    );
}

export default Gallery;